import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function InfoModal({ open, handleClose, message, title }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        onClick: handleClose,
      }}
    >
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
        onClick={handleClose}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} onClick={(e) => e.stopPropagation()}>
            <Card
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                padding: 3,
                boxShadow: 3,
                textAlign: "center",
              }}
            >
              <MKBox mb={2}>
                <MKTypography variant="h5" fontWeight="medium" color="text.primary">
                  {title || "Notice"}
                </MKTypography>
              </MKBox>
              <MKBox mb={3}>
                <MKTypography variant="body2" color="text.secondary">
                  {message}
                </MKTypography>
              </MKBox>
              <MKButton variant="contained" color="primary" onClick={handleClose} fullWidth>
                OK
              </MKButton>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </Modal>
  );
}

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default InfoModal;
