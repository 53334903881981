export const changeSubscription = async (newSubscription, billingCycle, subscriptionId = null) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Include cookies for session authentication
      body: JSON.stringify({ newSubscription, billingCycle, subscriptionId }), // Now send subscriptionId as well
    });

    if (!response.ok) {
      throw new Error("Failed to change subscription");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error changing subscription:", error);
    throw error;
  }
};

export const cancelSubscription = async (subscriptionId) => {
  try {
    // Cancel the subscription on PayPal via your backend
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/cancel-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies for session authentication
        body: JSON.stringify({ subscriptionId }), // Send subscriptionId to identify which subscription to cancel
      }
    );

    if (!response.ok) {
      throw new Error("Failed to cancel subscription");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error canceling subscription:", error);
    throw error;
  }
};
