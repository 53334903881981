import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import typography from "assets/theme/base/typography";

function SimpleFooter({ links, light }) {
  const { size } = typography;
  const navigate = useNavigate();

  const renderLinks = () =>
    links.map((link, key) => (
      <MKBox
        key={link.name}
        component="li"
        pl={key === 0 ? 0 : 2}
        pr={key === links.length - 1 ? 0 : 2}
        lineHeight={1}
        onClick={() => navigate(link.href)}
        sx={{ cursor: "pointer" }}
      >
        <MKTypography variant="button" fontWeight="regular" color={light ? "white" : "text"}>
          {link.name}
        </MKTypography>
      </MKBox>
    ));

  return (
    <MKBox
      component="footer"
      position="relative"
      bottom={0}
      width="100%"
      py={3}
      mt="auto"
      sx={{ zIndex: 2 }}
    >
      <Container>
        <MKBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()} Any Dubs. All rights reserved.
          </MKBox>
          <MKBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            {renderLinks()}
          </MKBox>
        </MKBox>
      </Container>
    </MKBox>
  );
}

SimpleFooter.defaultProps = {
  company: { href: "https://www.anydubs.com/", name: "Any Dubs" },
  links: [
    { href: "/contact-us", name: "Contact Us" },
    { href: "/terms-of-service", name: "Terms of Service" },
  ],
  light: false,
};

SimpleFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape),
  light: PropTypes.bool,
};

export default SimpleFooter;
