import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import GoogleIcon from "@mui/icons-material/Google";
//import FacebookIcon from "@mui/icons-material/Facebook";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
//import MKSocialButton from "components/MKSocialButton";

function SignInModal({ open, handleClose }) {
  const handleGoogleSignIn = () => {
    const redirectUrl = encodeURIComponent(window.location.href); // Current page as the callback URL
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        onClick: handleClose, // Close modal when clicking the backdrop
      }}
    >
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
        onClick={handleClose} // Close modal when clicking outside the card
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
          // Prevent closing when clicking inside the modal box
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} onClick={(e) => e.stopPropagation()}>
            <Card
              sx={{
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(100%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign In
                </MKTypography>
              </MKBox>
              <MKBox pt={5} pb={3} px={4}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKButton
                      variant="contained"
                      color="info"
                      fullWidth
                      sx={{ mb: 2, height: "7vh" }}
                      onClick={handleGoogleSignIn}
                    >
                      <GoogleIcon />
                      &nbsp; Sign in with Google
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </Modal>
  );
}

SignInModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SignInModal;
