import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Sidebar from "examples/Navbars/SideBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";

function ContactUsPage() {
  const selectedRoutes = [routes[0], routes[2], routes[3], routes[4]]; // Adjust indices as needed
  const selectedRoutesFoot = [routes[5], routes[1]]; // Adjust indices as needed

  return (
    <MKBox display="flex" minHeight="100vh">
      <Sidebar routes={selectedRoutes} footroutes={selectedRoutesFoot} />
      <MKBox
        sx={{
          marginLeft: "0px", // Use 70px for collapsed sidebar, 240px for expanded
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mt: 5,
        }}
      >
        <MKBox
          borderRadius="lg"
          shadow="xl"
          p={3}
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
            backgroundColor: "white",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <MKTypography variant="h4" textAlign="left" fontWeight="bold">
            Contact Us
          </MKTypography>

          <MKTypography variant="body2" textAlign="left" mt={2}>
            For any questions, inquiries, or feedback, please feel free to reach out to us at:
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={2} fontWeight="bold">
            Email: <a href="mailto:anydubsco@gmail.com">anydubsco@gmail.com</a>
          </MKTypography>

          <MKTypography variant="body2" textAlign="left" mt={2}>
            For better assistance, please provide the relevant details depending on your inquiry:
          </MKTypography>

          {/* Dubbing Request Bullet Points */}
          <MKBox component="ul" sx={{ paddingLeft: 3, mt: 2 }}>
            <MKBox component="li" mb={1}>
              <MKTypography variant="body2" textAlign="left">
                Account email address
              </MKTypography>
            </MKBox>
            <MKBox component="li" mb={1}>
              <MKTypography variant="body2" textAlign="left">
                File name
              </MKTypography>
            </MKBox>
            <MKBox component="li" mb={1}>
              <MKTypography variant="body2" textAlign="left">
                Upload date
              </MKTypography>
            </MKBox>
            <MKBox component="li" mb={1}>
              <MKTypography variant="body2" textAlign="left">
                Name on the card or PayPal account
              </MKTypography>
            </MKBox>
            <MKBox component="li" mb={1}>
              <MKTypography variant="body2" textAlign="left">
                A description of the issue
              </MKTypography>
            </MKBox>
          </MKBox>

          <MKTypography variant="body2" textAlign="left" mt={4}>
            Our team will get back to you as soon as possible.
          </MKTypography>
        </MKBox>

        <MKBox width="100%" mt="auto">
          <SimpleFooter dark />
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default ContactUsPage;
