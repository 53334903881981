// languages.js
const languages = {
  en: "English",
  hi: "Hindi",
  pt: "Portuguese",
  zh: "Chinese",
  es: "Spanish",
  fr: "French",
  de: "German",
  ja: "Japanese",
  ar: "Arabic",
  ru: "Russian",
  ko: "Korean",
  id: "Indonesian",
  it: "Italian",
  nl: "Dutch",
  tr: "Turkish",
  pl: "Polish",
  sv: "Swedish",
  fil: "Filipino",
  ms: "Malay",
  ro: "Romanian",
  uk: "Ukrainian",
  el: "Greek",
  cs: "Czech",
  da: "Danish",
  fi: "Finnish",
  bg: "Bulgarian",
  hr: "Croatian",
  sk: "Slovak",
  ta: "Tamil",
};

export default languages;
