import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Headerinfo from "pages/Presentation/sections/Headerinfo";
import MKTypography from "components/MKTypography";
import Pages from "pages/Presentation/sections/Pages";
import TrialBlock from "pages/Presentation/sections/TrialBlock";
import bgImage from "assets/images/background.png";
import SignInModal from "components/SignInModal";

function Presentation() {
  const { user, fetchUser } = useContext(AuthContext);
  const [openSignInModal, setOpenSignInModal] = useState(false);

  const handleOpenSignInModal = () => setOpenSignInModal(true);
  const handleCloseSignInModal = () => setOpenSignInModal(false);

  useEffect(() => {
    // Set up polling interval to fetch user every 5 seconds
    const intervalId = setInterval(() => {
      fetchUser();
    }, 5000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [fetchUser]);

  const selectedRoutes = [routes[0], routes[3], routes[4]];

  return (
    <MKBox display="flex" flexDirection="column" minHeight="100vh">
      <DefaultNavbar
        routes={selectedRoutes}
        action={
          user
            ? {
                type: "internal",
                route: "/download-center",
                label: "Profile",
                color: "primary",
              }
            : {
                type: "internal",
                onClick: handleOpenSignInModal,
                label: "Sign In",
                color: "primary",
              }
        }
        sticky
      />
      <MKBox
        flex="1"
        width="100%"
        minHeight="600px"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: { xs: "40px 20px", md: "60px 40px" },
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <MKTypography
              variant="h1"
              fontWeight="bold"
              color="light"
              textAlign="center"
              sx={{
                fontSize: { xs: "1.5rem", sm: "1.5rem", md: "2rem", lg: "2.5rem", xl: "3rem" },
                lineHeight: 1.2,
                marginBottom: { xs: 3, md: 30 },
                marginTop: { xs: "64px", sm: "70px", md: "80px", lg: "90px", xl: "100px" },
              }}
            >
              Expand your audience globally
              <br />
              starting today
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: "800px" }}>
            <Headerinfo />
          </Grid>
        </Grid>
      </MKBox>
      <Card
        sx={{
          p: 1,
          mx: { xs: 2, lg: 3 },
          mt: { xs: -4, lg: -8 },
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.7),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={{
            lg: 2,
            xl: 5,
          }}
        >
          <Grid item>
            <TrialBlock />
          </Grid>
          <Grid item>
            <MKBox
              sx={{
                width: { xs: "320px", sm: "500px", md: "500px", lg: "200px", xl: "550px" },
                maxWidth: "100%",
              }}
            >
              <Pages />
            </MKBox>
          </Grid>
        </Grid>
      </Card>
      <MKBox width="100%" mt="auto">
        <SimpleFooter dark />
      </MKBox>

      {/* Sign-In Modal */}
      <SignInModal open={openSignInModal} handleClose={handleCloseSignInModal} />
    </MKBox>
  );
}

export default Presentation;
