import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Headerinfo() {
  return (
    <MKBox component="section" py={0} mb={2} mt={{ xs: -5, md: -30 }}>
      <Container>
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems="stretch" justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <MKBox height="100%">
              <DefaultInfoCard
                icon="record_voice_over"
                title="Advanced Voice Cloning"
                direction="center"
                description={[
                  "Detect and clone multiple voices",
                  "Ensures natural and consistent audio",
                ]}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <MKBox height="100%">
              <DefaultInfoCard
                icon="mood"
                title="Expression Matching"
                direction="center"
                description={["Match and convey emotions", "Preserves original tone and intent"]}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <MKBox height="100%">
              <DefaultInfoCard
                icon="graphic_eq"
                title="Precision Voice Isolation"
                direction="center"
                description={[
                  "Isolate voice tracks effectively",
                  "Maintains original background track",
                ]}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Headerinfo;
