import React from "react";
import PropTypes from "prop-types";
import { CardMedia } from "@mui/material";

function VideoCard({ videoSrc, posterSrc }) {
  return (
    <CardMedia
      component="video"
      src={videoSrc}
      poster={posterSrc} // Set the poster image
      controls
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        objectFit: "cover",
      }}
    />
  );
}

VideoCard.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  posterSrc: PropTypes.string, // posterSrc is optional
};

export default VideoCard;
