import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

import logo from "assets/images/logo2.png";
import sidebarBg from "assets/images/sidebar.png";

const SidebarWrapper = styled(Drawer)(({ isCollapsed }) => ({
  width: isCollapsed ? 70 : 260,
  flexShrink: 0,
  whiteSpace: "nowrap",
  transition: "width 0.3s ease",
  "& .MuiPaper-root": {
    width: isCollapsed ? 70 : 260,
    overflow: "hidden",
    backgroundImage: `url(${sidebarBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    boxShadow: "none",
  },
}));

const SidebarHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
}));

const SidebarContent = styled(List)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme, isActive, isCollapsed }) => ({
  color: "#fff",
  padding: isCollapsed ? theme.spacing(1, 0) : theme.spacing(1, 2),
  justifyContent: "center", // Centers horizontally
  alignItems: "center", // Centers vertically
  backgroundColor: isActive ? "rgba(255, 255, 255, 0.2)" : "transparent",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ isCollapsed }) => ({
  color: "#fff",
  minWidth: isCollapsed ? "auto" : "56px", // Adjust minWidth when collapsed
  display: "flex", // Ensures icons align centrally
  justifyContent: "center", // Centers horizontally
  alignItems: "center", // Centers vertically
}));

const SidebarFooter = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: theme.spacing(1),
}));

const Sidebar = ({ routes, footroutes }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const location = useLocation();

  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <SidebarWrapper variant="permanent" isCollapsed={isCollapsed}>
      <SidebarHeader>
        {!isCollapsed && (
          <Link to="/">
            <img src={logo} alt="Logo" style={{ width: 120, height: "auto" }} />
          </Link>
        )}
        <IconButton onClick={handleToggle} style={{ color: "#fff" }}>
          {isCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </SidebarHeader>

      <SidebarContent>
        {routes.map((route, index) => (
          <StyledListItem
            button
            key={index}
            component={Link}
            to={route.route}
            isActive={location.pathname === route.route}
            isCollapsed={isCollapsed}
          >
            <StyledListItemIcon isCollapsed={isCollapsed}>{route.icon}</StyledListItemIcon>
            {!isCollapsed && <ListItemText primary={route.name} />}
          </StyledListItem>
        ))}
      </SidebarContent>

      <SidebarFooter>
        <Divider style={{ backgroundColor: "#fff" }} />
        <List>
          {footroutes.map((route, index) => (
            <StyledListItem
              button
              key={index}
              component={Link}
              to={route.route}
              isActive={location.pathname === route.route}
              isCollapsed={isCollapsed}
            >
              <StyledListItemIcon isCollapsed={isCollapsed}>{route.icon}</StyledListItemIcon>
              {!isCollapsed && <ListItemText primary={route.name} />}
            </StyledListItem>
          ))}
        </List>
      </SidebarFooter>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ).isRequired,
  footroutes: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default Sidebar;
