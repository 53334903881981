import { useState, useEffect } from "react";

export const useShopFunctions = (user) => {
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [dubLanguage, setDubLanguage] = useState("");
  const [progress, setProgress] = useState(0);
  const [videoMetadata, setMediaMetadata] = useState(null);
  const [speakers, setSpeakers] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [cost, setCost] = useState(0);
  const [open, setOpen] = useState(false);
  const [useFreeTrial, setUseFreeTrial] = useState(user ? user.freeTrials > 0 : false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [checkoutSuccessful, setCheckoutSuccessful] = useState(false);

  const freeTrials = user ? user.freeTrials : 0;

  // Automatically select free trial if free trials are available
  useEffect(() => {
    if (freeTrials > 0) {
      setUseFreeTrial(true);
    }
  }, [freeTrials]);

  useEffect(() => {
    const fetchCost = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/calculate-cost`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoDuration,
            userId: user ? user.userId : null,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setCost(data.cost);
        } else {
          setErrorMessage("Error calculating cost");
        }
      } catch (error) {
        setErrorMessage("Error calculating cost");
      }
    };

    if (videoDuration > 0 && !useFreeTrial) {
      fetchCost();
    } else {
      setCost(0);
    }
  }, [videoDuration, useFreeTrial, user]);

  const handleSourceLanguageChange = (event) => {
    setSourceLanguage(event.target.value);
  };

  const handleDubLanguageChange = (event) => {
    setDubLanguage(event.target.value);
  };

  const handleSpeakersChange = (event) => {
    setSpeakers(event.target.value);
  };

  const validExtensions = [
    // Video formats
    "mp4", // Widely supported video format
    "webm", // Supported in Chrome, Firefox, etc.
    "ogv", // Ogg format with Theora codec, supported in Firefox and some other browsers

    // Audio formats
    "mp3", // Widely supported audio format
    "wav", // Uncompressed format, large file sizes
    "ogg", // Supported by most modern browsers
    "m4a", // MPEG-4 audio, supported in many browsers
    "aac", // Common audio format, widely supported for audio-only
  ];

  const handleFileChange = (event) => {
    console.log("Event detected.");
    setProgress(0);
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      console.log("No file selected.");
      return;
    }

    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    const fileSizeMB = selectedFile.size / (1024 * 1024);

    if (!validExtensions.includes(fileExtension)) {
      setErrorMessage("Invalid file format. Please upload a valid video or audio file.");
      return;
    }
    console.log("Valid extension detected.");

    if (useFreeTrial && fileSizeMB > 100) {
      setErrorMessage("Free trial media must be under 100MB.");
      return;
    } else if (!useFreeTrial && fileSizeMB > 500) {
      setErrorMessage("Media must be under 500MB.");
      return;
    }

    // Select <video> or <audio> based on the file type
    const mediaElement =
      fileExtension === "mp4" || fileExtension === "webm" || fileExtension === "ogv"
        ? document.createElement("video")
        : document.createElement("audio");

    mediaElement.src = URL.createObjectURL(selectedFile);
    mediaElement.onloadedmetadata = () => {
      const durationMinutes = mediaElement.duration / 60;

      if (useFreeTrial && durationMinutes > 1) {
        setErrorMessage("Free trials can only be used for media under 1 minute.");
        return;
      } else if (!useFreeTrial && durationMinutes > 45) {
        setErrorMessage("Media must be under 45 minutes.");
        return;
      }
      console.log("Media metadata loaded.");
      setVideoDuration(durationMinutes);
      startMultipartUpload(selectedFile);
    };
  };

  const startMultipartUpload = async (file) => {
    try {
      const partSize = 5 * 1024 * 1024; // 5 MB per part
      const partCount = Math.ceil(file.size / partSize);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/media/initiate-multipart-upload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileName: file.name,
            fileType: file.type,
            partCount: partCount,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const { uploadId, uploadUrls, key } = await response.json();
        await uploadInChunks(file, uploadId, uploadUrls, key);
      } else {
        setErrorMessage("Error initiating multipart upload.");
      }
    } catch (error) {
      setErrorMessage("Error initiating multipart upload.");
      console.error(error);
    }
  };

  const uploadInChunks = async (file, uploadId, uploadUrls, key) => {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
    const chunks = Math.ceil(file.size / CHUNK_SIZE);
    let progress = 0;
    const parts = []; // Store part metadata

    for (let i = 0; i < chunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      try {
        const response = await fetch(uploadUrls[i], {
          method: "PUT",
          body: chunk,
        });

        if (!response.ok) {
          setErrorMessage("Error uploading chunk.");
          return;
        }

        // Collect the ETag for each part
        const eTag = response.headers.get("ETag");
        parts.push({
          ETag: eTag,
          PartNumber: i + 1, // PartNumber starts from 1
        });

        progress = Math.round(((i + 1) / chunks) * 100);
        setProgress(progress);
      } catch (error) {
        setErrorMessage("Error uploading chunk.");
        console.error(error);
        return;
      }
    }

    await completeMultipartUpload(uploadId, file.name, key, parts);
  };

  const completeMultipartUpload = async (uploadId, fileName, key, parts) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/media/complete-multipart-upload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uploadId, fileName, key, parts }),
          credentials: "include",
        }
      );
      if (response.ok) {
        const { videoMetadata } = await response.json();
        console.log("Multipart upload completed.");
        setMediaMetadata(videoMetadata);
      } else {
        setErrorMessage("Error completing multipart upload.");
      }
    } catch (error) {
      setErrorMessage("Error completing multipart upload.");
      console.error(error);
    }
  };

  const handleCheckout = async () => {
    if (!videoMetadata || !sourceLanguage || !dubLanguage) {
      setErrorMessage("Please upload a file and languages.");
      return;
    }

    if (useFreeTrial) {
      if (freeTrials <= 0) {
        setErrorMessage("No free trials remaining");
        return;
      }
      if (videoDuration > 1) {
        setErrorMessage("Free trial can only be used for videos under 1 minute");
        return;
      }
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          videoMetadata,
          sourceLanguage,
          dubLanguage,
          speakers,
          userId: user ? user.userId : null,
          cost: useFreeTrial ? 0 : cost,
        }),
        credentials: "include",
      });

      if (response.ok) {
        await response.json();
        setCheckoutSuccessful(true);

        if (useFreeTrial && freeTrials > 0) {
          const trialResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/use-free-trial`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: user.userId,
              }),
              credentials: "include",
            }
          );
          if (!trialResponse.ok) {
            setErrorMessage("Error updating free trial count");
          }
        }

        resetFields();
      } else {
        setErrorMessage("Error during checkout");
      }
    } catch (error) {
      setErrorMessage("Error during checkout");
    }
  };

  const clearMessages = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const resetFields = () => {
    setSourceLanguage("");
    setDubLanguage("");
    setProgress(0);
    setMediaMetadata(null);
    setVideoDuration(0);
    setCost(0);
    setUseFreeTrial(freeTrials > 0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    sourceLanguage,
    dubLanguage,
    speakers,
    progress,
    videoMetadata,
    videoDuration,
    cost,
    open,
    freeTrials,
    useFreeTrial,
    errorMessage,
    successMessage,
    checkoutSuccessful,
    handleSourceLanguageChange,
    setCheckoutSuccessful,
    handleDubLanguageChange,
    handleSpeakersChange,
    handleFileChange,
    handleCheckout,
    handleClose,
    setUseFreeTrial,
    clearMessages,
    resetFields,
  };
};
