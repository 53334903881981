import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultInfoCard({ icon, title, description, direction, small }) {
  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
      {typeof icon === "string" ? (
        <MKTypography
          display="block"
          variant={direction === "center" ? "h3" : "h3"}
          color="info"
          textGradient
          sx={{
            fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" }, // Responsive icon size
          }}
        >
          <Icon>{icon}</Icon>
        </MKTypography>
      ) : (
        icon
      )}
      <MKTypography
        display="block"
        color="white"
        variant="h5"
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
        sx={{
          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }, // Responsive title size
        }}
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        variant={small ? "button" : "button"}
        color="light"
        textAlign={direction}
        pr={direction === "left" ? 6 : 0}
        pl={direction === "right" ? 6 : 0}
        sx={{
          fontSize: { xs: "0.625rem", sm: "0.875rem", md: "1rem" }, // Responsive description size
          "& ul": {
            paddingLeft: "20px", // Consistent padding for bullet points
            margin: 0, // Remove default margin
            listStylePosition: "inside", // Ensure bullets are inside the content box
          },
          "& li": {
            marginBottom: { xs: "4px", sm: "6px", md: "8px" }, // Consistent spacing between list items
            lineHeight: 1.5, // Ensure consistent line height
          },
        }}
      >
        {Array.isArray(description) ? (
          <ul>
            {description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          description
        )}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default DefaultInfoCard;
