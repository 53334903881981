import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SignOut() {
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [signOutComplete, setSignOutComplete] = useState(false);

  useEffect(() => {
    signOut().then(() => {
      setSignOutComplete(true);
    });
  }, [signOut]);

  useEffect(() => {
    if (signOutComplete) {
      navigate(process.env.REACT_APP_FRONTEND_URL); // Redirect to the homepage after sign-out
    }
  }, [signOutComplete, navigate]);

  return (
    <MKBox textAlign="center" py={5}>
      <MKTypography variant="h4">Signing you out...</MKTypography>
    </MKBox>
  );
}

export default SignOut;
