import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/current_user`, {
        method: "GET",
        credentials: "include", // Include cookies for session authentication
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        console.error("Failed to fetch user:", response.statusText);
        setUser(null);
      }
    } catch (error) {
      console.error("Failed to fetch user", error);
      setUser(null);
    }
  };

  useEffect(() => {
    // Fetch user data on component mount
    fetchUser();
  }, []);

  const signOut = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {
        method: "GET",
        credentials: "include",
      });
      setUser(null); // This updates the user state to null, indicating the user is signed out
    } catch (error) {
      console.error("Failed to sign out", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, fetchUser, signOut }}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
