import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Sidebar from "examples/Navbars/SideBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";

function TermsOfServicePage() {
  const selectedRoutes = [routes[0], routes[2], routes[3], routes[4]]; // Adjust indices as needed
  const selectedRoutesFoot = [routes[5], routes[1]]; // Adjust indices as needed

  return (
    <MKBox display="flex" minHeight="100vh">
      <Sidebar routes={selectedRoutes} footroutes={selectedRoutesFoot} />
      <MKBox
        sx={{
          marginLeft: "0px", // Use 70px for collapsed sidebar, 240px for expanded
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          mt: 5,
        }}
      >
        <MKBox
          borderRadius="lg"
          shadow="xl"
          p={3}
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
            backgroundColor: "white",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <MKTypography variant="h4" textAlign="left" fontWeight="bold">
            Terms of Service
          </MKTypography>

          <MKTypography variant="body2" textAlign="left" mt={2}>
            Welcome to Any Dubs. By using our services, you agree to the following terms and
            conditions. Please read them carefully.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            1. Acceptance of Terms
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            By accessing and using our services, you accept and agree to be bound by these Terms of
            Service. If you do not agree to these terms, please do not use our services.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            2. Service Description
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            Any Dubs provides dubbing services for uploaded media. Our pricing is based on the
            duration of the media and your chosen subscription plan. All charges will be
            communicated clearly before processing your request.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            3. User Responsibilities
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            Users are solely responsible for the media they upload. You represent and warrant that
            you own the rights to the original media, or have obtained the necessary permissions to
            use, modify, and dub the media. You are also responsible for the rights to any dubbed
            media created using our services.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            4. Limitation of Liability
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            Any Dubs shall not be held liable for any issues, damages, or losses resulting from the
            use of our services, including but not limited to copyright infringement, data loss, or
            unauthorized use of your media. You agree to indemnify and hold Any Dubs harmless from
            any claims arising from your use of our services.
          </MKTypography>

          {/* New Service Quality Disclaimer Section */}
          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            5. Service Quality Disclaimer
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            Any Dubs does not guarantee consistent quality in dubbing services and is not
            responsible for any variations in dubbing quality. All services are provided on an
            &quot;as-is&quot; basis, and you accept any and all output quality at the time of
            delivery without expectation of revision or standardization.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            6. Modifications to Terms
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            We reserve the right to modify these Terms of Service at any time. Any changes will be
            effective immediately upon posting on our website. Your continued use of our services
            constitutes your acceptance of the revised terms.
          </MKTypography>

          <MKTypography variant="h6" textAlign="left" mt={4} fontWeight="bold">
            7. Contact Information
          </MKTypography>
          <MKTypography variant="body2" textAlign="left" mt={2}>
            For any questions or concerns regarding these Terms of Service, please contact us at:
            <a href="mailto:anydubsco@gmail.com"> anydubsco@gmail.com</a>.
          </MKTypography>

          <MKTypography variant="body2" textAlign="left" mt={4}>
            Last updated: 11/6/2024
          </MKTypography>
        </MKBox>

        <MKBox width="100%" mt="auto">
          <SimpleFooter dark />
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default TermsOfServicePage;
